import React from 'react';

import { useTypedSelector } from '@/store/store';
import { selectMenuItemListByAlias } from '@/store/reducers/tager/menus';
import { useRegion } from '@/hooks/useRegion';
import useSettingItem from '@/hooks/useSettingItem';
import { REGION } from '@/utils/region';
import { MenuAlias, PagesTemplates } from '@/typings/common';
import useLayoutPage from '@/hooks/useLayoutPage';

import { BottomProps } from './FooterBottom.types';
import { Item, InnerLink, Left, Right, Component } from './FooterBottom.styles';

function FooterBottom({ dark }: BottomProps) {
  const [region] = useRegion();
  const copyright = useSettingItem('FOOTER_COPYRIGHT');

  const currentPage = useLayoutPage();

  const bottomAlias =
    currentPage?.template === PagesTemplates.Links ||
    currentPage?.path === '/links/overview'
      ? MenuAlias.BottomLinks
      : region === REGION.ASIA
      ? MenuAlias.BottomAsia
      : MenuAlias.Bottom;

  const bottomMenuItemList =
    useTypedSelector((state) =>
      selectMenuItemListByAlias(state, bottomAlias)
    ) ?? [];

  return (
    <Component dark={dark}>
      <Left dangerouslySetInnerHTML={{ __html: copyright ?? '' }} />
      <Right>
        {bottomMenuItemList.map((menuItem) => (
          <Item key={menuItem.id}>
            <InnerLink
              to={menuItem.link}
              target={menuItem.isNewTab ? '_blank' : '_self'}
            >
              {menuItem.label}
            </InnerLink>
          </Item>
        ))}
      </Right>
    </Component>
  );
}

export default FooterBottom;
