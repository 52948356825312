import React from 'react';
import * as t from 'typed-contracts';
import { FormikErrors, FormikHelpers } from 'formik';

import {
  convertRequestErrorToMap,
  Nullable,
  RequestError,
  ResponseBody,
} from '@tager/web-core';
import { ParsedResponseBody } from '@tager/web-core/dist/typings/api';

import ErrorPage from '@/pages/_error';
import scrollManager from '@/services/scroll';

export function convertSlugToPath(
  slug: Array<string> | string | undefined
): string {
  if (!slug) return '/';

  if (Array.isArray(slug)) {
    return slug.map(convertSlugToPath).join('');
  }

  return '/' + slug;
}

export function getParamAsString(
  param: Array<string> | string | undefined
): string {
  return Array.isArray(param) ? param[0] : param ?? '';
}

export function convertErrorToProps(
  error: Error | RequestError
): React.ComponentProps<typeof ErrorPage> {
  if ('status' in error) {
    return { statusCode: error.status.code, title: error.status.text };
  }

  return { err: error, statusCode: 500 };
}

export function scrollDown() {
  const firstElement = document.querySelector('main > *:first-child');
  if (!firstElement) return;

  const scroll = scrollManager.getLocomotiveScroll();

  if (scroll) {
    scroll.scrollTo(firstElement.scrollHeight, 'easing');
  } else {
    window.scrollTo({
      top: firstElement.scrollHeight,
      behavior: 'smooth',
    });
  }
}

export function scrollToElement(ref: React.RefObject<any>) {
  const scroll = scrollManager.getLocomotiveScroll();

  if (scroll && ref.current) {
    scroll.scrollTo(ref.current, 'easing');
  } else {
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: 'smooth',
    });
  }
}

export function getElemOuterWidth(elem: HTMLElement) {
  const elemStyles = getComputedStyle(elem);

  return (
    parseFloat(elemStyles.marginLeft) +
    parseFloat(elemStyles.marginRight) +
    elem.offsetWidth
  );
}

export function getStringAsHtml(value: string) {
  const splitValue = value.split('');
  let isCloseTag = false;

  for (let i = 0; i < splitValue.length; i++) {
    if (splitValue[i] === '*') {
      if (!isCloseTag) {
        splitValue[i] = '<span>';
        isCloseTag = !isCloseTag;
      } else {
        splitValue[i] = '</span>';
        isCloseTag = !isCloseTag;
      }
    }
  }

  return splitValue.join('');
}

export function parseShortCodeSubMenu(value: string) {
  const firstIndex = value.indexOf('[');
  const lastIndex = value.indexOf(']');
  const shortCode = value.slice(firstIndex, lastIndex);
  let label: string;

  if (firstIndex !== -1 && lastIndex !== 1) {
    label = value.slice(0, firstIndex);
  } else {
    label = value;
  }

  const textShortCode = shortCode?.match(/text="(.*?)"/i) ?? [];
  const badgeShortCode = shortCode?.match(/badge="(.*?)"/i) ?? [];
  const productColorShortCode = shortCode?.match(/productColor="(.*?)"/i) ?? [];

  return {
    label: label,
    description: textShortCode[1] ?? '',
    productColor: productColorShortCode[1] ?? '',
    badge: badgeShortCode[1] ?? '',
  };
}

export function isValidRegExpTest(value: string, regExp: RegExp) {
  return regExp.test(value);
}

export function checkStringOnHiddenParamAndGetNewValue(
  value: string,
  regExp: RegExp
) {
  const firstIndex = value.indexOf('[');
  const lastIndex = value.indexOf(']');
  const shortCode = value.slice(firstIndex, lastIndex);

  if (!firstIndex || !lastIndex) return { value, hidden: false };

  return isValidRegExpTest(shortCode, regExp)
    ? { value: value.slice(0, firstIndex).trim(), hidden: true }
    : { value, hidden: false };
}

export function convertRequestError(
  error: Error
): Nullable<ParsedResponseBody> {
  if (error instanceof RequestError) {
    return error.body;
  } else {
    return null;
  }
}

type RequestErrorsType =
  | { type: 'validation'; error: Record<string, string> }
  | { type: 'any'; error: string };

export function getMessageByStatusCode(error: RequestError): string {
  switch (error.status.code) {
    case 401:
      return 'User is not authorized';
    case 403:
      return 'Forbidden';
    case 404:
      return `Server endpoint  is not found`;
    case 500:
      return 'Server error';
    case 502:
      return 'Server is not available';

    default:
      return error.message ?? 'Request error';
  }
}

export function getMessageFromRequestError(error: RequestError): string {
  const simpleMessage = getMessageByStatusCode(error);

  if (error.body && typeof error.body === 'object') {
    const responseBody = error.body as ResponseBody;

    return responseBody.message || responseBody.exception || simpleMessage;
  }

  return simpleMessage;
}

export function getMessageFromError(error: unknown): string {
  if (error instanceof RequestError) {
    return getMessageFromRequestError(error);
  }

  if (error instanceof Error) {
    return error.message;
  }

  return 'Unknown error';
}

export function handleRequestErrors(error: Error): RequestErrorsType {
  if (error instanceof RequestError) {
    const errors = convertRequestErrorToMap(error);
    if (!!Object.keys(errors).length) {
      return {
        type: 'validation',
        error: errors,
      };
    } else {
      return {
        type: 'any',
        error: getMessageFromError(error),
      };
    }
  } else {
    return { type: 'any', error: error.message };
  }
}
