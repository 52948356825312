import { useEffect } from 'react';
import { useRouter } from 'next/router';

function useRouteChange(callback: () => void) {
  const router = useRouter();

  useEffect(() => {
    router.events.on('beforeHistoryChange', callback);

    return () => {
      router.events.off('beforeHistoryChange', callback);
    };
  }, [callback, router.events]);
}

export default useRouteChange;
