import React, { useState } from 'react';

import { cookie } from '@tager/web-core';

import { ReactComponent as LocationSvg } from '@/assets/svg/location_ico.svg';
import { ReactComponent as VisaSvg } from '@/assets/svg/visa.svg';
import { ReactComponent as MasterCardSvg } from '@/assets/svg/mastercard.svg';
import { ReactComponent as MaestroSvg } from '@/assets/svg/maestro.svg';
import { ReactComponent as ArrowExpertise } from '@/assets/svg/arrow-4.svg';
import useRegion from '@/hooks/useRegion';
import { REGION } from '@/utils/region';

import {
  Component,
  SubMenu,
  SubMenuList,
  SubMenuItem,
  CityButton,
  SwitchButton,
  PaymentIcons,
  PaymentIcon,
} from './City.styles';
import { REGIONS_TITLES } from './City.helpers';

function City() {
  const [region, setRegion] = useRegion();
  const [showMenu, setShowMenu] = useState(false);
  const [regionTitle, setRegionTitle] = useState(REGIONS_TITLES.UK);

  const regionOptions = [
    { region: REGION.UK, title: REGIONS_TITLES.UK },
    { region: REGION.EU, title: REGIONS_TITLES.EU },
    { region: REGION.ASIA, title: REGIONS_TITLES.AS },
  ];

  function changeRegion(option: { region: string; title: string }) {
    cookie.set('region', option.region);
    setRegion(option.region);
    setRegionTitle(option.title);
    setShowMenu(false);
  }

  return (
    <Component>
      <CityButton
        customWidth={region === REGION.ASIA}
        onMouseOver={() => {
          setShowMenu(true);
        }}
        variant="blackLight"
      >
        <LocationSvg />
        <span>{regionTitle}</span>
        <ArrowExpertise />
        <SubMenu
          hidden={!showMenu}
          customWidth={region === (REGION.EU || REGION.UK)}
        >
          <SubMenuList>
            <SubMenuItem>
              {regionOptions.map((option) => (
                <SwitchButton onClick={() => changeRegion(option)}>
                  {option.title}
                </SwitchButton>
              ))}
            </SubMenuItem>
          </SubMenuList>
        </SubMenu>
      </CityButton>

      <PaymentIcons>
        <PaymentIcon>
          <VisaSvg />
        </PaymentIcon>
        <PaymentIcon>
          <MasterCardSvg />
        </PaymentIcon>
        <PaymentIcon maestro={true}>
          <MaestroSvg />
        </PaymentIcon>
      </PaymentIcons>
    </Component>
  );
}

export default City;
