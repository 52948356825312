import React, { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';

import { scroller, useDebounce, useMedia } from '@tager/web-core';

import { ReactComponent as LogoIcon } from '@/assets/svg/xpate.svg';
import Link from '@/components/Link';
import { colors } from '@/constants/theme';
import ContentSmallContainer from '@/components/ContentSmallContainer';
import { selectMenuItemListByAlias } from '@/store/reducers/tager/menus';
import { useTypedSelector } from '@/store/store';
import {
  checkStringOnHiddenParamAndGetNewValue,
  getStringAsHtml,
  parseShortCodeSubMenu,
} from '@/utils/common';
import useLayoutPage from '@/hooks/useLayoutPage';
import { FormPageType } from '@/typings/model';
import { MenuAlias, PagesTemplates } from '@/typings/common';
import { ReactComponent as ArrowIcon } from '@/assets/svg/arrow-6.svg';

import SubHeader from './components/SubHeader';
import MobileMenu from './components/MobileMenu/MobileMenu';
import MobileMenuToggle from './components/MobileMenuToggle/MobileMenuToggle';
import { HeaderProps } from './Header.types';
import { products, RELEASE_NOTES_TEMPLATE } from './Header.helpers';
import {
  Container,
  HeaderGroup,
  HeaderLogo,
  HeaderMenu,
  Inner,
  MenuItem,
  NavLink,
  NavLinkBadgeText,
  NavMenuList,
  NavText,
  SubMenu,
  SubMenuItem,
  SubMenuList,
  TryButton,
  WrapperNavLink,
  SwitchButtonLink,
  HeaderButtons,
} from './Header.styles';

function Header({ theme, dark, menuHidden }: HeaderProps) {
  const page = useLayoutPage<FormPageType>();
  const isMobile = useMedia(`(max-width: 768px)`);

  const isStacksPage =
    page?.templateFields &&
    (page?.template === PagesTemplates.Stacks ||
      page?.template === RELEASE_NOTES_TEMPLATE);

  const isNotShowStacksPage = isStacksPage && isMobile;

  const isXppPage = useDebounce(
    page?.templateFields &&
      (page?.template === PagesTemplates.XPP ||
        page?.templateFields.theme === PagesTemplates.XPP),
    1500
  );

  const isSingaporePage = useDebounce(
    page?.path.startsWith('/sg') || page?.path.startsWith('/xpate-sg'),
    1500
  );

  const isReleaseNotesPage = page?.template === RELEASE_NOTES_TEMPLATE;

  const router = useRouter();
  const currentPath = router.asPath.split('?')[0];

  const productValue = products[currentPath];

  const currentProduct = useDebounce(productValue, 1500);

  const headerLeftMenuItemList =
    useTypedSelector((state) =>
      selectMenuItemListByAlias(state, MenuAlias.HeaderLeft)
    ) ?? [];

  const headerRightMenuItemList =
    useTypedSelector((state) =>
      selectMenuItemListByAlias(state, MenuAlias.HeaderRight)
    ) ?? [];

  const isTabletLayout = useMedia(`(max-width: ${1130 - 1}px)`);

  const mobileMenuRef = useRef<HTMLDivElement>(null);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isMobileMenuActive, setMobileMenuActive] = useState(false);

  function handleMobileMenuClose() {
    setMobileMenuOpen(false);
  }

  function handleMenuToggleClick() {
    if (isMobileMenuActive) {
      setMobileMenuActive(false);
    } else {
      setMobileMenuActive(true);
      setMobileMenuOpen(true);
    }
  }

  useEffect(() => {
    if (isMobileMenuOpen) {
      scroller.lock(mobileMenuRef.current);
    }

    return () => {
      scroller.unlockAll();
    };
  }, [isMobileMenuOpen]);

  const createSignupButton = (mobileVisible: boolean) => (
    <Link to={isSingaporePage ? '/xpate-sg/form' : '/signup'} target="_blank">
      {(linkProps) => (
        <TryButton
          variant={theme === 'black' ? 'whiteRounded' : 'blackRounded'}
          {...linkProps}
          mobileVisible={mobileVisible}
        >
          Sign up
        </TryButton>
      )}
    </Link>
  );

  const loginButton = (
    <Link
      to={
        isSingaporePage ? 'https://gate.xpate.sg/' : 'https://portal.xpate.com/'
      }
      target={'_blank'}
    >
      {(linkProps) => (
        <TryButton
          variant={
            theme === 'black' ? 'outlinedRounded' : 'outlinedWhiteRounded'
          }
          {...linkProps}
        >
          Log in
        </TryButton>
      )}
    </Link>
  );

  return (
    <React.Fragment>
      <Container fixed={isMobileMenuActive} dark={dark}>
        <ContentSmallContainer>
          <Inner
            theme={theme}
            isXppPage={page?.template === PagesTemplates.XPP}
          >
            <HeaderLogo dark={dark} isActive={isMobileMenuActive} to={'/'}>
              <LogoIcon />
            </HeaderLogo>
            <HeaderMenu>
              {isReleaseNotesPage && (
                <SwitchButtonLink to="/">
                  for Business <ArrowIcon />
                </SwitchButtonLink>
              )}
              {!isTabletLayout && !menuHidden ? (
                <HeaderGroup>
                  <NavMenuList
                    ArrowIconColor={
                      theme === 'black' ? colors.white : colors.black
                    }
                  >
                    {headerLeftMenuItemList.map((menuItem, index) => {
                      const hasSubMenu = menuItem.children.length > 0;

                      const menuItemLabelConfig =
                        checkStringOnHiddenParamAndGetNewValue(
                          menuItem.label,
                          /hiddenOnXpp/gi
                        );

                      if (menuItemLabelConfig.hidden) {
                        return null;
                      }

                      return (
                        <MenuItem key={menuItem.id}>
                          <NavLink
                            hasLink={!!menuItem.link?.trim()}
                            to={menuItem.link}
                            target={menuItem.isNewTab ? '_blank' : '_self'}
                          >
                            {menuItemLabelConfig.value}
                          </NavLink>
                          {hasSubMenu ? (
                            <SubMenu>
                              <SubMenuList
                                bigMenu={
                                  index === headerLeftMenuItemList.length - 1
                                }
                              >
                                {menuItem.children.map((subItem) => {
                                  const subItemData = parseShortCodeSubMenu(
                                    subItem.label
                                  );

                                  return (
                                    <SubMenuItem
                                      hasLink={!!subItem.link?.trim()}
                                      key={subItem.id}
                                    >
                                      <WrapperNavLink>
                                        <NavLink
                                          productColor={
                                            subItemData.productColor
                                          }
                                          hasLink={!!subItem.link?.trim()}
                                          to={subItem.link ?? '#'}
                                          target={
                                            subItem.isNewTab
                                              ? '_blank'
                                              : '_self'
                                          }
                                          dangerouslySetInnerHTML={{
                                            __html: getStringAsHtml(
                                              subItemData.label
                                            ),
                                          }}
                                        />
                                        {subItemData.badge && (
                                          <NavLinkBadgeText>
                                            {subItemData.badge}
                                          </NavLinkBadgeText>
                                        )}
                                      </WrapperNavLink>
                                      {subItemData.description && (
                                        <NavText>
                                          {subItemData.description}
                                        </NavText>
                                      )}
                                    </SubMenuItem>
                                  );
                                })}
                              </SubMenuList>
                            </SubMenu>
                          ) : null}
                        </MenuItem>
                      );
                    })}
                  </NavMenuList>
                </HeaderGroup>
              ) : null}
              <HeaderGroup>
                {!isTabletLayout && !menuHidden ? (
                  <NavMenuList>
                    {headerRightMenuItemList.map((menuItem) => {
                      const hasSubMenu = menuItem.children.length > 0;

                      return (
                        <MenuItem key={menuItem.id}>
                          <NavLink
                            hasLink={!!menuItem.link?.trim()}
                            to={menuItem.link}
                            target={menuItem.isNewTab ? '_blank' : '_self'}
                          >
                            {menuItem.label}
                          </NavLink>
                          {hasSubMenu ? (
                            <SubMenu>
                              <SubMenuList>
                                {menuItem.children.map((subMenuItem) => (
                                  <SubMenuItem
                                    hasLink={!!subMenuItem.link?.trim()}
                                    key={subMenuItem.id}
                                  >
                                    <NavLink
                                      hasLink={!!subMenuItem.link?.trim()}
                                      to={subMenuItem.link}
                                      target={
                                        subMenuItem.isNewTab
                                          ? '_blank'
                                          : '_self'
                                      }
                                    >
                                      {subMenuItem.label}
                                    </NavLink>
                                  </SubMenuItem>
                                ))}
                              </SubMenuList>
                            </SubMenu>
                          ) : null}
                        </MenuItem>
                      );
                    })}
                  </NavMenuList>
                ) : null}
                {isXppPage ? (
                  <Link to="/start-now">
                    {(linkProps) => (
                      <TryButton
                        smallPadding={true}
                        variant={
                          theme === 'black' ? 'blackRounded' : 'blackRounded'
                        }
                        {...linkProps}
                        isActive={isMobileMenuActive}
                      >
                        Become our partner
                      </TryButton>
                    )}
                  </Link>
                ) : (
                  <HeaderButtons>
                    {!menuHidden ? loginButton : null}
                    {createSignupButton(false)}
                  </HeaderButtons>
                )}

                {menuHidden ? (
                  createSignupButton(true)
                ) : (
                  <MobileMenuToggle
                    isDark={!dark}
                    isActive={isMobileMenuActive}
                    onClick={handleMenuToggleClick}
                  />
                )}
              </HeaderGroup>
            </HeaderMenu>
          </Inner>
          {!isMobileMenuActive ? (
            <SubHeader
              dark={dark ?? false}
              product={isNotShowStacksPage ? null : currentProduct ?? null}
              theme={theme}
            />
          ) : null}
        </ContentSmallContainer>
      </Container>
      {isTabletLayout ? (
        isMobileMenuOpen ? (
          <MobileMenu
            mobileMenuRef={mobileMenuRef}
            isActive={isMobileMenuActive}
            onClose={handleMobileMenuClose}
          />
        ) : null
      ) : null}
    </React.Fragment>
  );
}

export default Header;
