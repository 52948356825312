import { Product, ThemeNameType, ThemeType } from './Header.types';

export const featureLabel = 'All features';

export const RELEASE_NOTES_TEMPLATE = 'release-notes';

export const ProcessingData = {
  productLabel: 'Processing',
  productLink: '/processing',
  featureLabel: featureLabel,
  featureLink: '/processing/overview',
};

export const exchangesData = {
  productLabel: 'Exchanges',
  productLink: '/exchanges',
  featureLabel: featureLabel,
  featureLink: '/exchanges/overview',
};

export const accountsData = {
  productLabel: 'Accounts',
  productLink: '/accounts',
  featureLabel: featureLabel,
  featureLink: '/accounts/overview',
};

export const linksData = {
  productLabel: 'xpate Links',
  productLink: '/links',
  featureLabel: featureLabel,
  featureLink: '/links/overview',
};

export const solutionsData = {
  productLabel: 'Solutions',
  productLink: '/solutions',
  featureLabel: featureLabel,
  featureLink: '/solutions/overview',
};

export const stacksData = {
  productLabel: 'xpate Stacks',
  productLink: '/stacks',
  featureLabel: 'Release Notes',
  featureLink: '/stacks/release-notes',
};

export const products: Record<string, Product> = {
  '/processing': ProcessingData,
  '/processing/overview': ProcessingData,
  '/accounts': accountsData,
  '/accounts/overview': accountsData,
  '/exchanges': exchangesData,
  '/exchanges/overview': exchangesData,
  '/links': linksData,
  '/links/overview': linksData,
  '/solutions': solutionsData,
  '/solutions/overview': solutionsData,
  '/stacks': stacksData,
  '/stacks/release-notes': stacksData,
};

export const themeColors: { [key in keyof typeof ThemeNameType]: ThemeType } = {
  white: {
    linkHoverColor: 'rgb(102, 102, 102)',
    buttonHover: 'rgb(102, 102, 102)',
    switchButtonHover: 'rgba(130, 130, 130, 0.1)',
  },
  black: {
    linkHoverColor: 'rgb(102, 102, 102)',
    buttonHover: 'rgb(218, 217, 220)',
    switchButtonHover: 'rgba(130, 130, 130, 0.1)',
  },
  acquiring: {
    linkHoverColor: '#434619',
    buttonHover: '#272818',
    switchButtonHover: '#E1EC44',
  },
  accounts: {
    linkHoverColor: '#044650',
    buttonHover: '#272818',
    switchButtonHover: '#19E5ED',
  },
  exchanges: {
    linkHoverColor: '#5A131D',
    buttonHover: '#272818',
    switchButtonHover: '#E53A38',
  },
  links: {
    linkHoverColor: '#046030',
    buttonHover: '#272818',
    switchButtonHover: '#00d06d',
  },
};
