import { colors } from '@/constants/theme';

export const subHeaderColors = {
  dark: {
    activeLinkColor: `rgba(255, 255, 255, 0.5)`,
    linkColor: colors.white,
  },
  light: {
    activeLinkColor: `rgba(4, 3, 18, 0.5)`,
    linkColor: '#040312',
  },
};

export function isActivePage(to: string, path: string) {
  return to.split('?')[0] === path.split('?')[0];
}
