import styled from 'styled-components';

const ContentSmallContainer = styled.div`
  max-width: 1276px;
  width: 100%;
  margin: 0 auto;
  padding: 0 0;

  @media (max-width: 1300px) {
    padding: 0 20px;
  }
`;

export default ContentSmallContainer;
