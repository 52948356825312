import styled from 'styled-components';

import Link from '@/components/Link';

import { ThemeNameType } from '../../Header.types';
import { themeColors } from '../../Header.helpers';

export const Component = styled.div`
  border-top: 1px solid rgba(130, 130, 133, 0.3);
  flex: 1 1 100%;
  max-width: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  z-index: 1;
`;

export const StyledLink = styled(Link)<{
  isActive: boolean;
  currentTheme: { activeLinkColor: string; linkColor: string };
}>`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin-right: 30px;
  color: ${(props) =>
    props.isActive
      ? props.currentTheme.activeLinkColor
      : props.currentTheme.linkColor};
  pointer-events: ${(props) => (props.isActive ? 'none' : 'initial')};
  cursor: ${(props) => (props.isActive ? 'initial' : 'pointer')};

  &:last-child {
    margin-right: 0;
  }
`;

export const Inner = styled.div<{ theme: ThemeNameType }>`
  ${StyledLink}:hover {
    color: ${(props) =>
      props.theme in themeColors
        ? themeColors[props.theme].linkHoverColor
        : '#000'};
  }
`;

export const LinksWrapper = styled.div`
  padding-top: 12px;
`;
