import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

export const MenuContainer = styled.div`
  margin-top: 95px;
  margin-right: -18px;

  ${media.mobile(css`
    margin-top: 75px;
  `)}
`;

export const FooterContainer = styled.footer<{
  simple: boolean;
  isXppPage: boolean;
}>`
  padding: ${(props) => (props.simple ? '30px 0' : '95px 0')};
  background: ${(props) => (!props.simple ? colors.black : colors.white)};
  color: ${(props) => (!props.simple ? colors.white : colors.black)};

  ${media.mobile(css`
    padding-bottom: 30px;
  `)}

  ${(props) =>
    props.isXppPage
      ? css`
          ${MenuContainer} {
            margin-top: 100px;

            ${media.mobile(css`
              margin-top: 38px;
            `)}
          }
        `
      : null}
`;

export const LogoContainer = styled.div``;

export const SloganContainer = styled.div`
  margin-top: 50px;

  ${media.laptop(css`
    margin-top: 40px;
  `)}

  ${media.mobile(css`
    margin-top: 65px;
  `)}
`;

export const CityRow = styled.div`
  margin-top: 104px;

  ${media.mobile(css`
    margin-top: 30px;
  `)}
`;

export const Bottom = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.23);
  padding-top: 41px;
  margin-top: 45px;

  ${media.mobile(css`
    padding-top: 30px;
    margin-top: 30px;
  `)}
`;
