import styled from 'styled-components';

import {colors} from '@/constants/theme';

export const Container = styled.div<{ dark?: boolean }>`
  color: ${(props) => (props.dark ? colors.white : colors.black)};
  position: fixed;
  min-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Main = styled.main`
  flex: 1 0 auto;
`;
