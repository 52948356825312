import styled, { css } from 'styled-components';

import { createMediaMixin } from '@tager/web-components';

import { breakpoints, colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

export const Component = styled.div`
  font-size: 13px;
  letter-spacing: 0;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.65);
  margin-top: 22px;

  a {
    cursor: pointer;
  }

  p {
    margin-top: 22px;

    &:first-child {
      margin-top: 0;
    }

    a:hover {
      color: ${colors.blueHover};
    }

    ${createMediaMixin({ min: breakpoints.tabletSmall })(css`
      line-height: 35px;
    `)}
  }

  ${media.mobile(css`
    margin-top: 20px;

    p {
      margin-top: 20px;
    }
  `)}
`;
