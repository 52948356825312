import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import Button from '@/components/Button';
import { fonts } from '@/constants/theme';

export const Component = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.mobile(css`
    display: block;
  `)}
`;

export const SubMenu = styled.div<{ hidden: boolean; customWidth: boolean }>`
  position: absolute;
  display: ${({ hidden }) => (hidden ? 'none' : 'block')};
  top: 100%;
  left: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 0.15s ease;
  padding-top: 22px;
  z-index: 100;
  max-width: ${({ customWidth }) => (customWidth ? '156px' : '188px')};
  width: 100%;
`;

export const SwitchButton = styled.span`
  width: 100%;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0;
  font-weight: 600;
  padding: 16px 22px;
  margin: 0 !important;
`;

export const SubMenuList = styled.ul`
  border-radius: 10px;
  background: #11101e;
`;

export const SubMenuItem = styled.li`
  text-align: left;
  width: inherit;

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  a {
    font-size: 17px;
    font-weight: 600;
    line-height: 25px;
    color: #828285;

    &:hover {
      color: #666;
    }
  }
`;

export const CityButton = styled(Button)<{ customWidth: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  min-width: 156px;
  padding-left: 20px;
  padding-right: 17px;
  max-width: ${({ customWidth }) => (customWidth ? '188px' : '156px')};
  width: 100%;
  transition: none;

  svg {
    fill: #fefefe;
    width: 12px;
    height: 13px;
    margin: 0;
  }

  svg:first-child {
    width: 13px;
    height: 18px;
  }

  span {
    display: block;
    margin: 0 13px -2px 13px;
    font-family: ${fonts.Gilroy};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0;
    text-align: left;
    svg {
      display: inline;
      width: 14px;
      height: 19px;
    }
  }

  &:hover {
    background-color: rgba(130, 130, 130, 0.1);

    div {
      z-index: 100;
      visibility: visible;
      opacity: 1;
    }
  }
`;

export const PaymentIcons = styled.div`
  display: flex;
  align-items: center;
  margin: 0 -12px;

  ${media.mobile(css`
    margin-top: 20px;
  `)}
`;

export const PaymentIcon = styled.div<{ maestro?: boolean }>`
  padding: 0 12px;

  svg {
    max-width: 48px;
  }

  margin-top: ${(props) => (props.maestro ? '-4px' : '0')};

  &:last-child svg {
    transform: translateY(5px);
  }
`;
