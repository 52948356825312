import React from 'react';

import ContentSmallContainer from '@/components/ContentSmallContainer';
import { ReactComponent as LogoWhiteIcon } from '@/assets/svg/xpate_black.svg';
import { FormPageType } from '@/typings/model';
import { PagesTemplates } from '@/typings/common';
import useLayoutPage from '@/hooks/useLayoutPage';

import Menu from './components/Menu';
import City from './components/City';
import FooterBottom from './components/FooterBottom';
import Copyright from './components/Copyright';
import { FooterProps } from './Footer.types';
import {
  CityRow,
  FooterContainer,
  LogoContainer,
  MenuContainer,
  Bottom,
} from './Footer.styles';

function Footer({ simple, footerNoCta = false }: FooterProps) {
  const page = useLayoutPage<FormPageType>();
  const isXppPage = page?.template === PagesTemplates.XPP;

  return (
    <FooterContainer isXppPage={isXppPage} simple={simple}>
      <ContentSmallContainer>
        {simple ? (
          <FooterBottom dark={true} />
        ) : (
          <>
            <LogoContainer>
              <LogoWhiteIcon />
            </LogoContainer>
            <MenuContainer>
              <Menu />
            </MenuContainer>
            <CityRow>
              <City />
            </CityRow>
            <Bottom>
              <FooterBottom dark={false} />
              <Copyright />
            </Bottom>
          </>
        )}
      </ContentSmallContainer>
    </FooterContainer>
  );
}

export default Footer;
