import React from 'react';
import { useRouter } from 'next/router';

import { SubHeaderProps } from './SubHeader.types';
import { Component, Inner, StyledLink, LinksWrapper } from './SubHeader.styles';
import { isActivePage, subHeaderColors } from './SubHeader.helpers';

function SubHeader({ theme, dark, product }: SubHeaderProps) {
  const router = useRouter();
  const currentTheme = dark ? subHeaderColors.dark : subHeaderColors.light;

  if (!product) {
    return null;
  }

  return (
    <Component>
      <Inner theme={theme}>
        <LinksWrapper>
          <StyledLink
            currentTheme={currentTheme}
            isActive={isActivePage(product.productLink, router.asPath)}
            to={product.productLink}
          >
            {product.productLabel ?? 'xpate Links'}
          </StyledLink>
          <StyledLink
            currentTheme={currentTheme}
            isActive={isActivePage(product.featureLink, router.asPath)}
            to={product.featureLink}
          >
            {product.featureLabel ?? 'All Features'}
          </StyledLink>
        </LinksWrapper>
      </Inner>
    </Component>
  );
}

export default SubHeader;
