import React from 'react';

import { ReactComponent as ArrowLinkSvg } from '@/assets/svg/arrow_link_2.svg';
import { useTypedSelector } from '@/store/store';
import { selectMenuItemListByAlias } from '@/store/reducers/tager/menus';
import { MenuAlias } from '@/typings/common';

import { Component, Cell, Item, InternalLink, Title } from './Menu.styles';

function Menu() {
  const footerMenuItemList =
    useTypedSelector((state) =>
      selectMenuItemListByAlias(state, MenuAlias.Footer)
    ) ?? [];

  return (
    <Component>
      {footerMenuItemList.map((menuItem, index) => {
        const hasSubItems = menuItem.children.length > 0;
        const firstColumn = index === 0;

        return (
          <Cell key={menuItem.id}>
            {menuItem.link ? (
              <Item key={menuItem.id}>
                <InternalLink
                  firstItem={!!menuItem.link}
                  isNewTabInFirstColumn={menuItem.isNewTab}
                  to={menuItem.link}
                  target={menuItem.isNewTab ? '_blank' : '_self'}
                >
                  {menuItem.label}
                  {menuItem.isNewTab && <ArrowLinkSvg />}
                </InternalLink>
              </Item>
            ) : (
              <Item>
                <Title>{menuItem.label}</Title>
              </Item>
            )}
            {hasSubItems
              ? menuItem.children.map((subMenuItem) => {
                  const isNewTab = subMenuItem.isNewTab;
                  return (
                    <Item key={subMenuItem.id}>
                      <InternalLink
                        isNewTabInFirstColumn={
                          firstColumn && subMenuItem.isNewTab
                        }
                        to={subMenuItem.link}
                        target={subMenuItem.isNewTab ? '_blank' : '_self'}
                      >
                        {subMenuItem.label}{' '}
                        {firstColumn && isNewTab && <ArrowLinkSvg />}
                      </InternalLink>
                    </Item>
                  );
                })
              : null}
          </Cell>
        );
      })}
    </Component>
  );
}

export default Menu;
