import React, { SyntheticEvent, useEffect, useState } from 'react';

import { useDebounce } from '@tager/web-core';

import ContentContainer from '@/components/ContentContainer';
import useRouteChange from '@/hooks/useRouteChange';
import useLayoutPage from '@/hooks/useLayoutPage';
import { useTypedSelector } from '@/store/store';
import { selectMenuItemListByAlias } from '@/store/reducers/tager/menus';
import {
  checkStringOnHiddenParamAndGetNewValue,
  getStringAsHtml,
  parseShortCodeSubMenu,
} from '@/utils/common';
import { FormPageType } from '@/typings/model';
import { MenuAlias, PagesTemplates } from '@/typings/common';
import scrollManager from '@/services/scroll';
import Link from '@/components/Link';

import MobileNavItem from '../MobileNavItem/MobileNavItem';
import MobileNavRender from '../MobileNavRender/MobileNavRender';

import { MobileMenuProps } from './MobileMenu.types';
import {
  Container,
  Content,
  NavText,
  NavLinkBadgeText,
  NavList,
  NavListItem,
  TryButton,
  Footer,
  FooterInner,
  SubMenuLink,
  SubMenuItem,
  Buttons,
} from './MobileMenu.styles';

function MobileMenu({ isActive, onClose, mobileMenuRef }: MobileMenuProps) {
  const page = useLayoutPage<FormPageType>();

  const result =
    page?.templateFields &&
    (page?.template === PagesTemplates.XPP ||
      page?.templateFields.theme === PagesTemplates.XPP);

  const isXppPage = useDebounce(result, 1500);

  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      scrollManager.getLocomotiveScroll()?.stop();
    } else {
      scrollManager.getLocomotiveScroll()?.start();
    }
  }, [isOpen]);

  const headerLeftMenuItemList =
    useTypedSelector((state) =>
      selectMenuItemListByAlias(state, MenuAlias.HeaderLeft)
    ) ?? [];
  const headerRightMenuItemList =
    useTypedSelector((state) =>
      selectMenuItemListByAlias(state, MenuAlias.HeaderRight)
    ) ?? [];

  const menuItemsList = headerLeftMenuItemList.concat(headerRightMenuItemList);

  useEffect(() => setOpen(isActive), [isActive]);

  function handleTransitionEnd(e: SyntheticEvent) {
    if (e.target !== e.currentTarget) {
      return;
    }
    if (!isActive) {
      onClose();
    }
  }

  useRouteChange(handleRouteChange);

  function handleRouteChange() {
    setOpen(false);
    onClose();
  }

  return (
    <Container isOpen={isOpen} onTransitionEnd={handleTransitionEnd}>
      <Content ref={mobileMenuRef}>
        <ContentContainer>
          <NavList>
            <MobileNavRender>
              {menuItemsList.length !== 0
                ? menuItemsList.map((menuItem) => {
                    const menuItemLabelConfig =
                      checkStringOnHiddenParamAndGetNewValue(
                        menuItem.label,
                        /hiddenOnXpp/gi
                      );

                    if (menuItemLabelConfig.hidden && isXppPage) {
                      return null;
                    }

                    return (
                      <NavListItem key={menuItem.id}>
                        <MobileNavItem
                          target={menuItem.isNewTab ? '_blank' : '_self'}
                          label={menuItemLabelConfig.value}
                          link={menuItem.link ?? ''}
                          onClick={(e) => {
                            if (menuItem.children.length) {
                              e.preventDefault();
                            }
                          }}
                        >
                          {menuItem.children.length !== 0
                            ? menuItem.children.map((subMenuItem, index) => {
                                const subMenuItemData = parseShortCodeSubMenu(
                                  subMenuItem.label
                                );

                                return (
                                  <SubMenuItem key={subMenuItem.id}>
                                    <SubMenuLink
                                      productColor={
                                        subMenuItemData.productColor
                                      }
                                      hasLink={!!subMenuItem.link?.trim()}
                                      to={subMenuItem.link}
                                      target={
                                        subMenuItem.isNewTab
                                          ? '_blank'
                                          : '_self'
                                      }
                                      dangerouslySetInnerHTML={{
                                        __html: getStringAsHtml(
                                          subMenuItemData.label
                                        ),
                                      }}
                                    />
                                    {subMenuItemData.badge && (
                                      <NavLinkBadgeText>
                                        {subMenuItemData.badge}
                                      </NavLinkBadgeText>
                                    )}
                                    {subMenuItemData.description && (
                                      <NavText>
                                        {subMenuItemData.description}
                                      </NavText>
                                    )}
                                  </SubMenuItem>
                                );
                              })
                            : null}
                        </MobileNavItem>
                      </NavListItem>
                    );
                  })
                : null}
            </MobileNavRender>
          </NavList>
        </ContentContainer>
      </Content>
      <Footer>
        <ContentContainer>
          <FooterInner>
            <Buttons>
              <Link to="https://portal.xpate.com/" target="_blank">
                {(linkProps) => (
                  <TryButton variant="outlinedRounded" {...linkProps}>
                    Log in
                  </TryButton>
                )}
              </Link>
              <Link to="/signup" target="_blank">
                {(linkProps) => (
                  <TryButton variant="whiteRounded" {...linkProps}>
                    Sign up
                  </TryButton>
                )}
              </Link>
            </Buttons>
          </FooterInner>
        </ContentContainer>
      </Footer>
    </Container>
  );
}

export default MobileMenu;
