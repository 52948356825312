import styled, { css } from 'styled-components';

import { createMediaMixin } from '@tager/web-components';

import { media } from '@/utils/mixin';

export const Component = styled.button<{ isActive: boolean; isDark?: boolean }>`
  position: relative;
  display: none;
  min-width: 48px;
  min-height: 48px;
  margin-left: 30px;
  border-radius: 50%;
  background: #fff;
  transition: all 0.3s ease;

  ${createMediaMixin({ max: 1130 })(css`
    display: block;
  `)}
  ${media.mobile(css`
    margin-left: 15px;
  `)}
  ${media.mobileMedium(css`
    width: 42px;
    height: 42px;
  `)}
  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: 2px;
    background: #040312;
    transition: background 0.3s ease;

    &:before,
    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      transform-origin: 0 50%;
      background: #040312;
      transition: all 0.3s ease;
    }

    &:before {
      bottom: 6px;
    }

    &:after {
      top: 6px;
    }
  }

  ${({ isActive }) =>
    isActive &&
    css`
      span {
        background: transparent;

        &:before {
          transform: rotate(45deg) translate3d(1px, -4px, 0);
        }

        &:after {
          transform: rotate(-45deg) translate3d(1px, 4px, 0);
        }

        ${media.mobileMedium(css`
          &:before {
            transform: rotate(45deg) translate3d(1px, -3px, 0);
          }

          &:after {
            transform: rotate(-45deg) translate3d(1px, 3px, 0);
          }
        `)}
      }
    `}

  ${({ isDark, isActive }) =>
    isDark &&
    css`
      background: #000;

      span {
        &,
        &:before,
        &:after {
          background: #fff;
        }
      }

      ${isActive &&
      css`
        background: #fff;

        span {
          background: transparent;

          &:before,
          &:after {
            background: #040312;
          }
        }
      `}
    `}
`;
