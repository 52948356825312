import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import Link from '@/components/Link';

export const Component = styled.div``;

export const SubMenuToggle = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  color: inherit;
  transition: 0.3s;

  svg {
    width: 50%;
    height: 50%;
    transition: transform 0.15s ease;
  }
`;

export const NavItemInner = styled.div<{ isActive: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #7c7c80;

  &:hover {
    color: #fff;
  }

  ${media.mobile(css`
    &:hover {
      color: #7c7c80;
    }
  `)}

  ${({ isActive }) =>
    isActive &&
    css`
      color: #fff;

      ${SubMenuToggle} svg {
        transform: rotate(180deg) translateZ(0);
      }
    `}
`;

export const NavItemLink = styled(Link)`
  font-size: 37px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 50px;

  ${media.mobile(css`
    font-size: 27px;
    line-height: 40px;
  `)}
`;

export const SubMenuWrap = styled.div<{ isOpen: boolean }>`
  position: relative;
  max-height: 0;
  transition: max-height 0.3s ease;
  overflow: hidden;
`;

export const SubMenuList = styled.ul`
  padding-top: 10px;
`;

export const SubMenuItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 5px;
  }
`;
