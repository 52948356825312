import { useContext } from 'react';

import { RegionContext } from '@/components/RegionProvider';

export function useRegion(): [string | undefined, (value: string) => void] {
  const region = useContext(RegionContext);
  if (!region) {
    throw new Error('use the hook only in the provider context');
  }
  return [region.region, region.changeRegion];
}

export default useRegion;
