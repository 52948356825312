import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import Link from '@/components/Link';

export const Left = styled.span`
  font-size: 13px;
  letter-spacing: 0;
  line-height: 23px;

  ${media.tablet(css`
    margin-bottom: 20px;
  `)}
  ${media.mobile(css`
    margin-bottom: 0;
  `)}
`;

export const Right = styled.ul`
  display: flex;
  margin-top: -5px;

  ${media.tabletSmall(css`
    margin-top: 20px;
  `)}

  ${media.mobile(css`
    flex-wrap: wrap;
  `)}
`;

export const Item = styled.li`
  font-size: 13px;
  letter-spacing: 0;
  line-height: 34px;
  white-space: nowrap;

  &:not(:last-child) {
    margin-right: 32px;
  }

  ${media.mobile(css`
    width: 100%;

    &:not(:last-child) {
      margin-right: 0;
    }
  `)}
`;

export const InnerLink = styled(Link)``;

export const Component = styled.div<{ dark: boolean }>`
  display: flex;
  justify-content: space-between;
  color: ${(props) => (!props.dark ? 'rgba(255, 255, 255, 0.65)' : '#000')};

  ${InnerLink} {
    color: ${(props) => (!props.dark ? 'rgba(255, 255, 255, 0.65)' : '#000')};

    &:hover {
      color: ${(props) => (!props.dark ? '#fff' : '#333')};
    }
  }

  ${media.tablet(css`
    flex-wrap: wrap;
  `)}
`;
