import { FunctionComponent } from 'react';
import { css } from 'styled-components';

import { Nullable } from '@tager/web-core';

import { CustomApp_PageContext } from '@/typings/hocs';

export type PageModuleType = {
  component: FunctionComponent;
  getInitialProps?: (context: CustomApp_PageContext) => Promise<unknown>;
  template: string;
};

export type StringFieldType = Nullable<string>;

export type CssSnippet = ReturnType<typeof css>;

export enum MenuAlias {
  HeaderLeft = 'header-left',
  HeaderRight = 'header-right',
  Footer = 'footer',
  Bottom = 'bottom',
  BottomAsia = 'bottom-asia',
  BottomLinks = 'bottom-links',
}

export enum PagesTemplates {
  Basic = 'basic',
  XPP = 'xpp',
  Links = 'links',
  Stacks = 'stacks',
  Singapore = 'singapore',
}
