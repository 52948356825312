import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import Link from '@/components/Link';
import { media } from '@/utils/mixin';
import Button, { ButtonLink } from '@/components/Button';

import { ThemeNameType } from './Header.types';
import { themeColors } from './Header.helpers';

export const Container = styled.header<{ dark?: boolean; fixed: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  color: ${colors.black};
  z-index: 999;

  ${({ dark }) =>
    dark &&
    css`
      color: ${colors.white65};
    `}

  ${({ fixed }) =>
    fixed &&
    css`
      position: fixed;
    `}
`;

export const HeaderLogo = styled(Link)<{ dark?: boolean }>`
  display: inline-block;
  margin-right: 33px;
  transition: color 0.3s ease;
  color: ${(props) => (props.dark ? '#fff' : '#000')};

  ${({ isActive }) =>
    isActive &&
    css`
      color: #ffffff;
    `}

  ${media.mobile(css`
    margin-right: 15px;
  `)}
`;

export const WrapperNavLink = styled.span`
  display: flex;
  position: relative;

  a {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
`;

export const NavLinkBadgeText = styled.div`
  position: absolute;
  right: 1px;
  top: -10px;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  color: #0060f5;
`;

export const HeaderMenu = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 1 1px;
`;

export const HeaderGroup = styled.div`
  display: flex;
  align-items: center;

  &:last-child {
    margin-left: auto;
    margin-top: -5px;
  }
`;

export const NavMenuList = styled.ul<{ ArrowIconColor?: string }>`
  display: flex;

  &:not(:last-child) {
    margin-right: 32px;
  }

  ${media.tabletSmall(css`
    display: none;
  `)}
  svg {
    margin-right: 3px;
    transform: rotate(-90deg);
    align-self: center;
  }

  svg path {
    fill: ${(props) => props.ArrowIconColor};
  }
`;

export const SubMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  visibility: hidden;
  opacity: 0;
  padding-top: 22px;
  transform: translateY(-2em);
  width: 100%;
  z-index: 1;
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
    z-index 0s linear 0.01s;
`;

export const NavLink = styled(Link)<{
  hasLink: boolean;
  productColor?: string;
}>`
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0;
  font-weight: 600;
  pointer-events: ${(props) => (props.hasLink ? 'initial' : 'none')};

  span {
    color: ${(props) => props.productColor};
    transition: inherit;
  }
`;

export const NavText = styled.span`
  margin-top: 5px;
  font-weight: normal;
  font-size: 12px;
  line-height: 130%;
  color: #828385;
`;

export const menuItemCss = css`
  &:hover {
    ${SubMenu} {
      visibility: visible;
      opacity: 1;
      z-index: 1;
      transform: translateY(0%);
      transition-delay: 0.1s;
    }
  }
`;

export const SwitchButtonLink = styled(Link)`
  display: flex;
  align-items: center;
  padding: 12px 22px 11px;
  margin-right: 33px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  min-height: 48px;
  color: ${colors.black};
  border-radius: 24px;
  background: rgba(4, 3, 18, 0.06);
  white-space: nowrap;
  cursor: pointer;

  @media (max-width: 768px) {
    display: none;
  }

  svg {
    margin-left: 9px;

    path {
      fill: ${colors.black};
    }
  }

  &:hover {
    color: #666;
  }
`;

export const SwitchButtonInner = styled.div`
  visibility: hidden;
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  padding-top: 10px;
  opacity: 0;
  transform: translateY(-2em);
  width: 100%;
  z-index: 1;
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
    z-index 0s linear 0.01s;
`;

export const SwitchButton = styled(Button)<{
  color: string;
  switchButtonHoverColor: string;
  active: boolean;
}>`
  ${menuItemCss};
  position: relative;
  margin-right: 18px;
  color: ${(props) => props.color};
  padding: 0 24px;
  background: inherit;

  color: ${(props) => props.color};
  background: ${(props) => props.switchButtonHoverColor};

  &:hover {
    color: ${(props) => props.color};
    background: ${(props) => props.switchButtonHoverColor};
  }

  ${media.tabletSmall(css`
    display: none;
  `)}
  &:hover {
    ${SwitchButtonInner} {
      visibility: visible;
      opacity: 1;
      z-index: 1;
      transform: translateY(0%);
      transition-delay: 0s, 0s, 0.3s;
    }
  }
`;

export const MenuItem = styled.li`
  ${menuItemCss};
  position: relative;

  a {
    display: block;
    width: 100%;
  }

  &:first-child {
    a {
      padding-right: 15px;
    }
  }

  &:not(:first-child) {
    a {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &:last-child {
    a {
      padding-right: 0;
    }
  }

  ${media.tabletLarge(css`
    &:first-child {
      a {
        padding-right: 10px;
      }
    }

    &:not(:first-child) {
      a {
        padding-right: 10px;
        padding-left: 10px;
      }

      &:last-child {
        a {
          padding-right: 0;
        }
      }
    }
  `)}
`;

export const SubMenuList = styled.ul<{ bigMenu?: boolean }>`
  position: relative;
  border-radius: 10px;
  background: #2a2934;
  width: 193px;
  padding: 16px 22px;

  ${(props) =>
    props.bigMenu &&
    css`
      width: 220px;
    `}
  &:before {
    position: absolute;
    content: '';
    display: block;
    width: 11.5px;
    height: 11.5px;
    background: #2a2934;
    top: -5px;
    transform: rotate(45deg);
    left: 30px;
  }
`;

export const SubMenuItem = styled.li<{ hasLink: boolean }>`
  text-align: left;

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  a {
    font-size: 17px;
    font-weight: 600;
    line-height: 25px;
    color: ${(props) => (props.hasLink ? colors.white : '#666')};

    ${(props) =>
      !props.hasLink
        ? css`
            color: #666;
          `
        : null}
  }
`;

export const TryButton = styled(ButtonLink)<{
  isActive: boolean;
  smallPadding?: boolean;
  mobileVisible?: boolean;
}>`
  padding: 0 31px !important;
  white-space: nowrap;

  ${(props) =>
    props.smallPadding
      ? css`
          ${media.mobile(css`
            padding: 0 17px !important;
          `)}
        `
      : null}

  ${media.mobileMedium(css`
    display: none;
  `)}


  ${(props) =>
    props.mobileVisible &&
    css`
      display: none;
    `}

  ${(props) =>
    props.mobileVisible &&
    media.mobileMedium(css`
      display: flex;
    `)}

  ${({ isActive }) =>
    isActive &&
    css`
      background: #ffffff;
      color: #11101e;
    `}
`;

export const Inner = styled.div<{ theme: ThemeNameType; isXppPage: boolean }>`
  display: flex;
  align-items: center;
  padding: 35px 0;

  ${media.tabletSmall(css`
    padding: 22px 0;
  `)}
  ${NavLink}:hover {
    color: rgb(102, 102, 102);
  }

  ${NavLink}:hover {
    span {
      color: rgb(102, 102, 102);
    }
  }

  ${TryButton}:hover {
    background: ${(props) =>
      props.theme in themeColors
        ? themeColors[props.theme].buttonHover
        : 'inherit'};
  }

  ${(props) =>
    props.isXppPage
      ? css`
          ${NavMenuList} {
            color: ${colors.white};
          }
        `
      : null}
`;

export const HeaderButtons = styled.div`
  display: flex;

  > *:not(:first-child) {
    margin-left: 10px;
  }

  ${media.mobile(css`
    display: none;
  `)}
`;
