import React from 'react';

import { MobileNavRenderProps } from './MobileNavRender.types';

function MobileNavRender({ children }: MobileNavRenderProps) {
  return (
    <React.Fragment>
      {Array.isArray(children)
        ? children.map((child, index) => (
            <React.Fragment key={index}>
              {React.isValidElement(child)
                ? React.cloneElement(child, {
                    style: { animationDelay: `${0.05 * (index + 1)}s` },
                  })
                : child}
            </React.Fragment>
          ))
        : children}
    </React.Fragment>
  );
}

export default MobileNavRender;
