import React from 'react';

import { MobileMenuToggleProps } from './MobileMenuToggle.types';
import { Component } from './MobileMenuToggle.styles';

function MobileMenuToggle({
  isDark,
  isActive,
  onClick,
}: MobileMenuToggleProps) {
  return (
    <Component isDark={isDark} isActive={isActive} onClick={onClick}>
      <span />
    </Component>
  );
}

export default MobileMenuToggle;
