import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';

const ContentContainer = styled.div<{ width?: number }>`
  max-width: ${(props) => (props.width ? props.width + 'px' : '1592px')};
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;

  ${media.mobile(css`
    padding: 0 20px;
  `)}
`;

export default ContentContainer;
