import styled, { css } from 'styled-components';

import { CssSnippet } from '@tager/web-components';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

export type ReservedButtonVariant =
  | 'contained'
  | 'outlined'
  | 'blackLight'
  | 'black'
  | 'blue'
  | 'yellow'
  | 'outlinedRounded'
  | 'outlinedWhiteRounded'
  | 'whiteRounded'
  | 'whiteRounded58'
  | 'blackRounded'
  | 'blackRounded58';

export type ButtonVariant = ReservedButtonVariant | CssSnippet;

const variantCssMap: Record<ReservedButtonVariant, CssSnippet> = {
  contained: css`
    background-color: #24292e;
    opacity: 0.95;
    color: white;
    border: 1px solid #24292e;

    &:hover {
      background-color: black;
    }
  `,
  outlined: css`
    background-color: white;
    color: #24292e;
    border: 1px solid #24292e;

    &:hover {
      background-color: #24292e;
      opacity: 0.95;
      color: white;
    }
  `,
  blackLight: css`
    background: rgba(130, 130, 130, 0.1);
    color: #fff;
    height: 48px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    font-weight: 600;
    border-radius: 24px;

    &:hover {
      background-color: #171623;
      color: white;
    }
  `,
  whiteRounded: css`
    background: #fff;
    color: #000;
    height: 48px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    font-weight: 600;
    border-radius: 24px;
    transition: 0.5s all ease;

    &:hover {
      background: #dad9dc;
    }
  `,
  whiteRounded58: css`
    background: #fff;
    color: #000;
    height: 58px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    font-weight: 600;
    border-radius: 60px;
    transition: 0.5s all ease;

    &:hover {
      background: #dad9dc;
    }
  `,
  outlinedRounded: css`
    color: white;
    border: 1px solid #fff;

    height: 48px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    font-weight: 600;
    border-radius: 24px;
    transition: 0.5s all ease;

    &:hover {
      background: #fff !important;
      color: #000;
      opacity: 1;
    }
  `,

  outlinedWhiteRounded: css`
    color: #000;
    border: 1px solid #000;
    height: 48px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    font-weight: 600;
    border-radius: 24px;
    transition: 0.5s all ease;

    &:hover {
      background: #000 !important;
      color: #fff;
      opacity: 1;
    }
  `,

  blackRounded: css`
    background: #000;
    color: #fff;
    height: 48px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    font-weight: 600;
    border-radius: 24px;
    transition: 0.5s all ease;

    &:hover {
      background: rgba(130, 130, 130, 0.5);
    }
  `,

  blackRounded58: css`
    background: #000;
    color: #fff;
    height: 58px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    font-weight: 600;
    border-radius: 60px;
    transition: 0.5s all ease;

    &:hover {
      background: rgba(130, 130, 130, 0.5);
    }
  `,
  black: css`
    background: #000;
    color: #fff;
    height: 56px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    font-weight: 600;
    border: 1px solid #000;
    border-radius: 34px;

    &:hover {
      background: #292935;
    }

    ${media.mobileMedium(css`
      height: 48px;
      font-size: 14px;
      line-height: 20px;
    `)}
  `,
  yellow: css`
    background: ${colors.acquiringPrimary};
    color: ${colors.black};
    height: 56px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    font-size: 16px;
    border-radius: 34px;
    letter-spacing: 0;
    line-height: 22px;
    font-weight: 600;

    &:hover {
      background: ${colors.acquiringPrimaryHover};
    }

    ${media.mobileMedium(css`
      height: 48px;
      font-size: 14px;
      line-height: 20px;
    `)}
  `,

  blue: css`
    background: ${colors.accountsPrimary};
    color: ${colors.black};
    height: 56px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    font-weight: 600;
    border-radius: 34px;

    &:hover {
      background: ${colors.accountsPrimaryHover};
    }

    ${media.mobileMedium(css`
      height: 48px;
      font-size: 14px;
      line-height: 20px;
    `)}
  `,
};

export const StyledButton = styled.button<{
  fullWidth?: boolean;
  variant?: ButtonVariant;
}>`
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  letter-spacing: 0.1em;
  text-align: center;
  text-decoration: none;
  transition: ease all 0.2s;
  cursor: pointer;

  svg {
    margin-left: 10px;
  }

  ${(props) =>
    typeof props.variant === 'string'
      ? variantCssMap[props.variant]
      : props.variant};

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
`;
