import React from 'react';

import Preloader from '@/components/Preloader';

import Header from './components/Header';
import Footer from './components/Footer';
import { LayoutProps } from './Layout.types';
import { Container, Main } from './Layout.styles';

function Layout({
  theme,
  children,
  isPreloaderHidden,
  dark,
  simpleFooter = false,
  onPreloaderHide,
  onPreloaderOut,
  footerNoCta = false,
  headerMenuHidden = false,
}: LayoutProps) {
  return (
    <React.Fragment>
      <Container dark={dark} id="layout">
        <Header theme={theme} dark={dark} menuHidden={headerMenuHidden} />
        <Main>{children}</Main>
        <Footer simple={simpleFooter} footerNoCta={footerNoCta} />
      </Container>
      <Preloader
        hidden={isPreloaderHidden}
        onHide={onPreloaderHide}
        onOut={onPreloaderOut}
        debug={true}
      />
    </React.Fragment>
  );
}

export default Layout;
