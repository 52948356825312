import gsap from 'gsap';
import { CustomEase } from 'gsap/dist/CustomEase';

gsap.registerPlugin(CustomEase);

export const colors = {
  white: 'white',
  white65: 'rgba(255,255,255,0.65)',
  black: '#040312',
  black20: 'rgba(4,3,18,0.2)',
  black100: '#101010',
  black200: '#262626',
  black300: '#3b3b3b',
  textGray: '#ccc',
  gray: '#f6f6f6',
  gray20: '#f6f6f6',
  grayDark: '#f2f2f2',
  grayLight: '#fafafa',
  red: 'red',
  blueDark: '#1442FA',
  blue: '#0060f5',
  blue75: 'rgba(0,96,245,0.75)',
  blueHover: '#126BF6',
  acquiringPrimary: '#F2FE39',
  acquiringPrimary75: 'rgba(242,254,57,0.75)',
  acquiringPrimaryHover: '#F3FE4C',
  accountsPrimary: '#07F6FF',
  accountsPrimaryHover: '#07EDF5',
  exchangesPrimary: '#ff403c',
  exchangesPrimaryHover: '#f03d3a',
  linksPrimary: '#01E176',
  linksPrimaryHover: '#07cf6f',
  xppPrimary: '#955DFF',
  xppPrimaryHover: '#8247ee',
  stacksPrimary: '#00F7EC',
  stacksPrimaryHover: '#00e5db',
  singaporePrimary: '#955DFF',
} as const;

/** Source: https://htmlacademy.ru/blog/useful/css/short-14 */
const fallbackFont = [
  'Gilroy',
  '-apple-system',
  "'BlickMacSystemFont'",
  "'Segoe UI'",
  "'Roboto'",
  "'Oxygen'",
  "'Ubuntu'",
  "'Cantarell'",
  "'Fira Sans'",
  "'Droid Sans'",
  "'Helvetica Neue'",
  'sans-serif',
].join(',');

export const fonts = {
  Montserrat: `'Montserrat', ${fallbackFont}`,
  Gilroy: `'Gilroy', ${fallbackFont}`,
};

/** GSAP Custom easing **/
CustomEase.create('customEaseOut', '.25,.74,.22,.99');
CustomEase.create(
  'customEaseInOut',
  'M0,0 C0.212,0 0.247,0.014 0.326,0.09 0.402,0.164 0.46,0.356 0.502,0.504 0.551,0.68 0.582,0.832 0.642,0.898 0.714,0.977 0.734,1 1,1'
);

/**
 * Reference - Screen Resolution Stats Worldwide:
 * https://gs.statcounter.com/screen-resolution-stats
 *
 * 16px - scrollbar width on Windows,
 * some browsers doesn't include scrollbar width when calculate media queries
 */
export const breakpoints = {
  /** iPhone 5/SE */
  mobileSmall: 320,
  /** iPhone 6/7/8/X */
  mobileMedium: 375,
  /** iPhone 6/7/8 Plus */
  mobileLarge: 414,
  /** iPad 1, 2, Mini and Air */
  tabletSmall: 768,
  tabletLarge: 1024,
  /** 1280 - 16 = 1264 -> 1260 - more beautiful number :) */
  laptop: 1260,
  /** 1536 - 16 = 1520 -> 1500 - more beautiful number :) */
  desktop: 1400,
};
