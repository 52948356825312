import React, { useState } from 'react';

import { ReactComponent as AccordArrowIcon } from '@/assets/svg/arrow-down.svg';

import { MobileNavItemProps } from './MobileNavItem.types';
import {
  Component,
  SubMenuList,
  SubMenuWrap,
  SubMenuToggle,
  SubMenuItem,
  NavItemLink,
  NavItemInner,
} from './MobileNavItem.styles';

function MobileNavItem({
  label,
  link,
  children,
  className,
  target,
  onClick,
}: MobileNavItemProps) {
  const [isActive, setActive] = useState(false);

  function handleSubMenuToggleClick() {
    setActive(!isActive);
  }

  return (
    <Component className={className}>
      <NavItemInner isActive={isActive}>
        <NavItemLink
          target={target}
          to={link}
          onClick={(e) => {
            if (children) {
              e.preventDefault();
              handleSubMenuToggleClick();
            }
          }}
        >
          {label}
        </NavItemLink>
        {children ? (
          <SubMenuToggle onClick={handleSubMenuToggleClick}>
            <AccordArrowIcon />
          </SubMenuToggle>
        ) : null}
      </NavItemInner>
      {children ? (
        <SubMenuWrap isOpen={isActive}>
          <SubMenuList>
            {Array.isArray(children) ? (
              children.map((child, index) => (
                <SubMenuItem key={index}>{child}</SubMenuItem>
              ))
            ) : (
              <SubMenuItem>{children}</SubMenuItem>
            )}
          </SubMenuList>
        </SubMenuWrap>
      ) : null}
    </Component>
  );
}

export default MobileNavItem;
