import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';

import * as S from './Mask.styles';

type Props = {
  animate: boolean;
  backgroundUrl?: string;
  onAnimationEnd: () => void;
  className?: string;
};

function Mask({ animate, onAnimationEnd, className }: Props) {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!animate) return;

    const tl = gsap
      .timeline({
        onComplete: onAnimationEnd,
      })
      .to(
        containerRef.current,
        {
          ease: 'customEaseOut',
          translateY: '-100%',
          duration: 0.8,
          delay: 1,
        },
        0
      );

    return () => {
      tl.kill();
    };
  }, [animate]);

  return <S.Overlay ref={containerRef} className={className} />;
}

export default Mask;
