import React from 'react';

import useSettingItem from '@/hooks/useSettingItem';
import useRegion from '@/hooks/useRegion';
import { REGION, REGION_DISCLAIMER } from '@/utils/region';
import { PagesTemplates } from '@/typings/common';
import useLayoutPage from '@/hooks/useLayoutPage';

import { Component } from './Copyright.styles';

function Copyright() {
  const [currentRegion] = useRegion();

  const page = useLayoutPage();

  const disclaimerAlias = () => {
    if (
      page?.template === PagesTemplates.Links ||
      page?.path === '/links/overview'
    ) {
      return 'FOOTER_DISCLAIMER_LINKS';
    } else if (currentRegion) {
      return REGION_DISCLAIMER[currentRegion];
    }

    return REGION_DISCLAIMER[REGION.UK];
  };

  const disclaimer = useSettingItem(disclaimerAlias());

  return (
    <Component>
      <p dangerouslySetInnerHTML={{ __html: disclaimer ?? '' }} />
    </Component>
  );
}

export default Copyright;
