import styled, { css, keyframes } from 'styled-components';

import { media } from '@/utils/mixin';
import Link from '@/components/Link';
import { ButtonLink, ButtonNextLink } from '@/components/Button/Button';

export const Container = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  padding-top: 132px;
  background: #040312;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  z-index: 998;

  ${media.tabletSmall(css`
    padding-top: 92px;
  `)}

  ${media.mobileMedium(css`
    padding-top: 86px;
  `)}

  ${({ isOpen }) =>
    isOpen &&
    css`
      visibility: visible;
      opacity: 1;
    `}
`;

export const NavLinkBadgeText = styled.span`
  display: inline-block;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #0060f5;
  transform: translateY(-11px);
`;

export const Content = styled.div`
  flex: 1 1 1px;
  overflow-y: auto;
`;

export const SubMenuItem = styled.li`
  margin-bottom: 12px;
`;

export const NavText = styled.span`
  display: block;
  margin-top: 5px;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  color: #828385;
`;

export const NavList = styled.ul`
  padding-bottom: 30px;
`;

const showNavItem = keyframes`
  0% {
    transform: translateY(-20px);
    opacity: 0
  }
  80% {
    opacity: 1;
  }
  100% {
    transform: translateY(0);
    opacity: 1
  }
`;

export const NavListItem = styled.li`
  transform: translateY(0);
  animation: ${showNavItem} 0.15s cubic-bezier(0.4, 0, 0.2, 1) forwards;
  opacity: 0;

  &:not(:last-child) {
    margin-bottom: 30px;
  }

  ${media.mobile(css`
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  `)}
`;

export const SubMenuLink = styled(Link)<{
  productColor?: string;
  hasLink: boolean;
}>`
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 40px;
  color: #7c7c80;
  pointer-events: ${(props) => (props.hasLink ? 'initial' : 'none')};

  ${(props) =>
    !props.hasLink
      ? css`
          color: #666;
        `
      : null}
  span {
    transition: all 0.3s;
    color: ${(props) => props.productColor};
  }

  &:hover {
    color: #fff;

    span {
      color: #fff;
    }
  }

  ${media.mobile(css`
    font-size: 20px;
    line-height: 30px;
  `)}
`;

export const Footer = styled.div``;

export const FooterInner = styled.div`
  padding: 20px 0;
  border-top: 1px solid rgba(124, 124, 128, 0.32);
`;

export const ConsumerList = styled.ul`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
`;

export const ConsumerItem = styled.li`
  flex: 1;
`;

export const ConsumerLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 30px;
  color: #7c7c80;

  &:hover {
    color: #fff;
  }
`;

export const ConsumerIcon = styled.i`
  display: inline-flex;
  margin-left: 10px;
  padding-top: 4px;

  ${media.mobileSmall(css`
    display: none;
  `)}
`;

export const TryButton = styled(ButtonLink)`
  padding: 0 36px !important;
`;

export const Buttons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;
