import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import Link from '@/components/Link';

export const Component = styled.div`
  display: flex;

  ${media.mobile(css`
    flex-wrap: wrap;
  `)}
`;

export const Cell = styled.div`
  flex: 1 1 1px;

  ${media.mobile(css`
    flex-basis: 50%;
    max-width: 50%;

    &:not(:nth-child(-n + 2)) {
      margin-top: 36px;
    }
  `)}
  svg:first-child {
    margin-left: 5px;
  }
`;

export const ExternalLink = styled.a`
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;
  font-weight: 600;
  transition: 0.3s;

  svg,
  path {
    transition: 0.3s;
  }

  &:hover {
    color: ${colors.blueHover};

    svg path {
      fill: ${colors.blueHover};
    }
  }
`;

export const Item = styled.div`
  margin-top: 0;

  &:first-child {
    margin-bottom: 5px;
  }

  &:last-child {
    ${ExternalLink} {
      margin-top: 6px;
      display: inline-block;
    }
  }
`;

export const InternalLink = styled(Link)<{
  isNewTabInFirstColumn: boolean;
  firstItem?: boolean;
}>`
  display: inline-block;
  font-size: ${({ isNewTabInFirstColumn, firstItem }) =>
    isNewTabInFirstColumn || firstItem ? '19px' : '16px'};
  letter-spacing: 0;
  line-height: ${({ firstItem }) => (firstItem ? 'normal' : '34px')};
  color: ${({ isNewTabInFirstColumn, firstItem }) =>
    isNewTabInFirstColumn || firstItem ? '#fff' : colors.white65};
  font-weight: 600;

  transition: 0.3s;

  path {
    transition: 0.3s;
  }

  &:hover {
    color: ${({ isNewTabInFirstColumn, firstItem }) =>
      isNewTabInFirstColumn || firstItem ? colors.blueHover : '#fff'};

    svg path {
      fill: ${colors.blueHover};
    }
  }

  svg {
    display: inline;
    font-weight: 600;
    width: 10px;
    height: 9px;
  }

  ${media.mobile(css`
    line-height: 24px;
  `)}
`;

export const Title = styled.span`
  display: block;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 22px;
  font-weight: 600;
`;
