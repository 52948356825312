import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CustomEase } from 'gsap/dist/CustomEase';
import gsap from 'gsap';

import { notFalsy, useUpdateEffect } from '@tager/web-core';

import { isPreloaderVisible } from './Preloader.helpers';
import Mask from './components/Mask';

gsap.registerPlugin(CustomEase);

declare global {
  interface Window {
    isPreloaderHidden: boolean | undefined;
  }
}

type PreloaderStatus = 'VISIBLE' | 'FADING_OUT' | 'HIDDEN';

type Props = {
  hidden?: boolean;
  className?: string;
  debug?: boolean;
  onHide?: () => void;
  onOut?: () => void;
};

function Preloader({
  hidden: hiddenProp,
  className,
  debug,
  onHide,
  onOut,
}: Props) {
  const isControlled = hiddenProp !== undefined;

  function isInitiallyVisible(): boolean {
    const isVisible = isPreloaderVisible();

    return isControlled ? !hiddenProp : isVisible;
  }

  const [status, setStatus] = useState<PreloaderStatus>(
    isInitiallyVisible() ? 'VISIBLE' : 'HIDDEN'
  );

  useEffect(() => {
    if (!debug) return;

    console.log(
      `%c [DEBUG Preloader]: isControlled - ${isControlled}`,
      'color: green'
    );
    console.log(
      `%c [DEBUG Preloader]: isInitiallyVisible - ${isInitiallyVisible()}`,
      'color: green'
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debug]);

  useEffect(() => {
    if (!debug) return;

    console.log(`%c [DEBUG Preloader]: status - ${status}`, 'color: green');
  }, [debug, status]);

  function handleAnimationEnd(): void {
    setStatus('HIDDEN');
    onHide && onHide();
  }

  useUpdateEffect(() => {
    if (isControlled) {
      setStatus(hiddenProp ? 'FADING_OUT' : 'VISIBLE');
    }
  }, [hiddenProp, isControlled]);

  useEffect(() => {
    if (isControlled || status !== 'VISIBLE') return;

    function hidePreloader() {
      window.isPreloaderHidden = true;
      setStatus('FADING_OUT');
      onOut && onOut();
    }

    if (document.readyState !== 'loading') {
      hidePreloader();
      return;
    }

    document.addEventListener('DOMContentLoaded', hidePreloader);

    return () => {
      document.removeEventListener('DOMContentLoaded', hidePreloader);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isControlled]);

  if (status === 'HIDDEN') {
    return null;
  }

  const containerClassName = [
    status === 'FADING_OUT' ? 'fade-out' : 'status-' + status,
    className,
  ]
    .filter(notFalsy)
    .join(' ');

  return (
    <MaskElem
      className={containerClassName}
      animate={status === 'FADING_OUT'}
      onAnimationEnd={handleAnimationEnd}
    />
  );
}

const MaskElem = styled(Mask)`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99999;
`;

export default Preloader;
